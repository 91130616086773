import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Content from '../../content/home/content';
import Logo from '../../logos/enpax_logo_white.svg'

export default class homePage extends Component {

    render() {

        const content = Content;

        return (

            <div>

                <div className="pagecontent" style={{backgroundImage:`url(${content.src})`}}>

                    <NavLink to="/">
                        <img src={Logo} className="logo" alt="Environmental Peacebuilding" height="36px"/>
                    </NavLink>

                    <div className="pagecontent--content">

                        <div className="pagecontent--feature">

                            <p className="pagecontent--title">{content.title}</p>

                            <p className="pagecontent--text">{content.content}</p>

                            <p className="pagecontent--text"><a href="https://forms.gle/2sd4ezyEc2KDhuG69">Call for Artists</a></p>

                            <p className="pagecontent--text"><a href="https://forms.gle/CAiRJCJPFearjhDf9">Call for Creative Submissions</a></p>

                            <br/>

                            <p className="pagecontent--text">FEATURED</p>

                            <span>
                                <NavLink to="/jason-houston">
                                    <button className="statement__btn">
                                        Jason Houston
                                    </button>
                                </NavLink>
                                <NavLink to="rueda-photos">
                                    <button className="statement__btn">
                                        Rueda Photos
                                    </button>
                                </NavLink>
                                <NavLink to="michael-chew">
                                    <button className="statement__btn">
                                        Michael Chew
                                    </button>
                                </NavLink>
                                <NavLink to="mirali-shukla">
                                    <button className="statement__btn">
                                        Mirali Shukla
                                    </button>
                                </NavLink>
                                <NavLink to="conservation-international">
                                    <button className="statement__btn">
                                        Conservation International
                                    </button>
                                </NavLink>
                            </span>
                        </div>

                        <p className="copyright">Copyright © <a href="https://www.environmentalpeacebuilding.org">Environmental Peacebuilding Association</a>® 2022. Photograph courtesy <a href="https://www.jasonhouston.com/">Jason Houston</a>. All rights reserved.</p>

                    </div>

                </div>

            </div>

        );

    }

}

