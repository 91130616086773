import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import houstonJasonArtist from './views/artists/houston_jason.artist';
import ruedaPhotosArtist from './views/artists/rueda_photos.artist';
import conservationInternationalArtist from './views/artists/conservation_international.artist.js';
import chewMichaelArtist from './views/artists/chew_michael.artist.js';
import shuklaMiraliArtist from './views/artists/shukla_mirali.artist.js';
import homePage from './views/pages/home.page.js';

class App extends Component {

    render () {

        return (

            <BrowserRouter>

                <div className='container-fluid'>

                    <Switch>

                        <Route exact path='/' component={homePage} />
                        <Route exact path='/jason-houston' component={houstonJasonArtist} />
                        <Route exact path='/rueda-photos' component={ruedaPhotosArtist} />
                        <Route exact path='/conservation-international' component={conservationInternationalArtist} />
                        <Route exact path='/michael-chew' component={chewMichaelArtist} />
                        <Route exact path='/mirali-shukla' component={shuklaMiraliArtist} />

                    </Switch>

                </div>

            </BrowserRouter>

        );

    }

}

export default hot(module)(App);
