import React, { Component } from 'react';
import Slider from '../../components/Slider';
import Images from '../../assets/conservation_international/gallery';
import Statement from '../../assets/conservation_international/statement';
import Logo from "../../logos/enpax_logo_white.svg";
import { NavLink } from "react-router-dom";

export default class conservationInternationalArtist extends Component {

    state = {
        isShow: true
    }

    handleShow = () => {

        // clear current slide when component loads
        sessionStorage.removeItem('i');

        this.setState({
            isShow: !this.state.isShow
        })

    };

    render() {

        const statement = Statement

        const {
            isShow
        } = this.state

        return (

            <div>

                {isShow && (

                    <div className="statement" style={{backgroundImage:`url(${statement.src})`}}>

                        <NavLink to="/">
                            <img src={Logo} className="logo" alt="Environmental Peacebuilding" height="36px"/>
                        </NavLink>

                        <div className="statement--content">

                            <div className="statement--feature">

                                <p className="statement--artist">{statement.artist}</p>

                                <p className="statement--title">{statement.title}</p>

                                <p className="statement--text">{statement.statement}</p>

                                <button className="statement__btn" onClick={this.handleShow}>
                                    Enter Gallery
                                </button>

                            </div>

                        </div>

                        <p className="copyright">Copyright © <a href="https://www.environmentalpeacebuilding.org">Environmental Peacebuilding Association</a>® 2022. All rights reserved.</p>

                    </div>

                )}

                {!isShow && (

                    <React.Fragment>

                        <Slider images={Images}/>

                        <button onClick={this.handleShow} className="slider__btn-hide" style={{position:"absolute",top:"20",right:"20"}}>
                            <i className="fas fa-times-circle"></i>
                        </button>

                    </React.Fragment>

                )}

            </div>

        );

    }

}

